<template>
    <ValidationObserver tag="form" class="billing" ref="observer" @submit.prevent="onSubmit">
        <div class="billing__header">
            <h3 class="h3 billing__title">Заказать акт сверки</h3>
        </div>
        <div class="billing__body">
            <h3 class="h3 billing__title">Укажите плательщика и период</h3>
            <div class="form-row mt-30">
                <ValidationProvider tag="div" vid="start_date" v-slot="{errors}" :rules="`required`" class="form-group col-md-6">
                    <label class="billing__label">Начальная дата сверки *</label>
                    <VueDatePicker
                        class="form-control"
                        color="#8e8e8e"
                        :validate="true"
                        :noHeader="true"
                        :noCalendarIcon="true"
                        :locale="{lang: 'ru'}"
                        :format="'DD MMM YYYY'"
                        placeholder=""
                        :clearable="true"
                        v-model="form.startDate" />
                    <span class="v-error animated fadeInDown" v-if="errors.length">
                        {{errors[0]}}
                    </span>
                </ValidationProvider>
                <ValidationProvider tag="div" vid="end_date" v-slot="{errors}" :rules="`required`" class="form-group col-md-6">
                    <label class="billing__label">Конечная дата сверки *</label>
                    <VueDatePicker
                        class="form-control"
                        color="#8e8e8e"
                        :validate="true"
                        :noHeader="true"
                        :noCalendarIcon="true"
                        :locale="{lang: 'ru'}"
                        :format="'DD MMM YYYY'"
                        placeholder=""
                        :clearable="true"
                        :minDate="form.startDate"
                        v-model="form.endDate" />
                     <span class="v-error animated fadeInDown" v-if="errors.length">
                        {{errors[0]}}
                    </span>
                </ValidationProvider>
            </div>
            <div class="form-row">
                <ValidationProvider tag="div" vid="payer" v-slot="{errors}" :rules="`required`" class="form-group col-md-12">
                    <label class="billing__label" for="id_payer">
                        Плательщик <span class="billing__required">*</span>
                    </label>
                    <v-select2 class="form-material"
                               id="id_payer"
                               v-model="form.payer"
                               :reduce="name => name.id"
                               :options="payers" label="name"
                               :clearable="false" :searchable="true">
                        <span slot="no-options">Ничего не найдено</span>
                    </v-select2>
                    <span class="v-error animated fadeInDown" v-if="errors.length">
                        {{errors[0]}}
                    </span>
                </ValidationProvider>
            </div>
        </div>
        <div class="form-check billing__footer">
            <button type="submit" class="v-btn">Заказать сверку</button>
            <button type="button" class="v-btn v-btn--transparent" @click="$modal.hide('ActOrder')">Отменить</button>
        </div>
    </ValidationObserver>
</template>

<script>
    import session from '@/api/session';
    import {mapState} from "vuex";

    export default {
        name: "ActOrder",
        data() {
            return {
                payers: [],
                form: {
                    startDate: null,
                    endDate: null,
                    payer: null
                }
            }
        },
        created() {
            this.updatePayers()
        },
        computed: {
            ...mapState('default_data', [
                'current_user',
            ])
        },
        watch: {
            'form.startDate': {
                handler: function (val, oldVal) {
                    if (this.form.endDate && Date.parse(val) > Date.parse(this.form.endDate)) {
                        this.form.endDate = val;
                    }
                },
                deep: true
            }
        },
        methods: {
            async updatePayers() {
                try {
                    const request = session.get('/api/v1/replenishment-balance/default_form/');
                    let response = await request;
                    this.payers = response.data.payers.filter(item => item.type !== 'physical');
                } catch (err) {
                    this.errorHandler(err);
                }
            },
            async onSubmit() {
                let isValidModal = await this.$refs.observer.validate();
                if (isValidModal) {
                    try {
                        const request = session.post('/api/v1/act-payer/order/', this.form);
                        let response = await request;
                        this.$modal.hide('ActOrder');
                        this.$swal({
                            title: 'Ваш акт обрабатывается!',
                            text: `Акт сверки будет отправлен на ваш email: ${this.current_user.email}`,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    } catch (err) {
                        console.error(err)
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../billing";

    .v--modal-overlay[data-modal="ActOrder"] {
        .v--modal-box {
            max-width: 1000px !important;
            overflow: visible;
        }
    }

</style>
